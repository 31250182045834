import React from "react"
import { documentHubPage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const DocumentHub = () => {
  return (
    <Layout>
      <Seo
        title="Simplify Accounting Workflow with Synkli’s Document Hub"
        description={`Streamline your accounting workflow with Synkli's document hub. Collect, store, and organise financial data and tax documents for better accounting practice management.`}
      />

      <FeatureDetailsPage data={documentHubPage} />
      
    </Layout>
  )
}

export default DocumentHub
